const TEXT_SIZES = {
  xs: "text-xs 2xl:text-sm",
  sm: "text-sm 2xl:text-base",
  normal: "text-base lg:text-lg 2xl:text-xl",
  md: "md:text-lg lg:text-2xl 2xl:text-3xl",
  lg: "text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl",
  xl: "text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl",
};

export default function Text({
  size = "normal",
  type = "span",
  className = "",
  children,
  id,
}) {
  const Component = type;
  return (
    <Component id={id} className={`${TEXT_SIZES[size]} ${className}`}>
      {children}
    </Component>
  );
}
