import React, { Suspense, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import NavBar from "./Components/NavBar/NavBar";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import { trackNavigation } from "./utils/track";
import { ReCaptchaContext } from "./hooks/useReCaptcha";
import RippleAnim from "./Components/RippleAnim";

const HowWeWork = React.lazy(() => import("./pages/HowWeWork/HowWeWork"));
const Contact = React.lazy(() => import("./pages/Contact/Contact"));
const Services = React.lazy(() => import("./pages/Services/Services"));
const CaseStudies = React.lazy(() => import("./pages/CaseStudies/CaseStudies"));
const MobileApplication = React.lazy(() =>
  import("./pages/Services/MobileApplication/MobileApplication")
);
const WebApplication = React.lazy(() =>
  import("./pages/Services/WebApplication/WebApplication")
);
const TeamAugmentation = React.lazy(() =>
  import("./pages/Services/TeamAugmentation/TeamAugmentation")
);
const ProjectRescue = React.lazy(() =>
  import("./pages/Services/ProjectRescue/ProjectRescue")
);
const ProductDesign = React.lazy(() =>
  import("./pages/Services/ProductDesign/ProductDesign")
);
const DevOps = React.lazy(() => import("./pages/Services/DevOps/DevOps"));
const Blockchain = React.lazy(() =>
  import("./pages/Services/Blockchain/Blockchain")
);
const Company = React.lazy(() => import("./pages/Company/Company"));
const StartUps = React.lazy(() => import("./pages/StartUps/StartUps"));
const MVP = React.lazy(() => import("./pages/StartUps/MVP/MVP"));
const Enterprises = React.lazy(() => import("./pages/Enterprises/Enterprises"));
const ApplicationDevelopment = React.lazy(() =>
  import("./pages/Enterprises/ApplicationDevelopment/ApplicationDevelopment")
);
const LegacyApplication = React.lazy(() =>
  import("./pages/Enterprises/LegacyApplication/LegacyApplication")
);
const SoftwareIntegration = React.lazy(() =>
  import("./pages/Enterprises/SoftwareIntegration/SoftwareIntegration")
);
const EuYanSang = React.lazy(() =>
  import("./pages/CaseStudies/EuYanSang/EuYanSang")
);
const PopularBookStores = React.lazy(() =>
  import("./pages/CaseStudies/PopularBookStores/PopularBookStores")
);
const NtucIncome = React.lazy(() =>
  import("./pages/CaseStudies/NtucIncome/NtucIncome")
);
const CharlesMonat = React.lazy(() =>
  import("./pages/CaseStudies/CharlesMonat/CharlesMonat")
);
const GoldenVillage = React.lazy(() =>
  import("./pages/CaseStudies/GoldenVillage/GoldenVillage")
);
const TzuChi = React.lazy(() => import("./pages/CaseStudies/TzuChi/TzuChi"));
const ChiropracticSingapore = React.lazy(() =>
  import("./pages/CaseStudies/ChiropracticSingapore/ChiropracticSingapore")
);
const DBS = React.lazy(() => import("./pages/CaseStudies/DBS/DBS"));
const Exceltec = React.lazy(() => import("./pages/CaseStudies/Exceltec/Exceltec.jsx"));
const OneSystemsTechnologies = React.lazy(() => import("./pages/CaseStudies/OneSystemsTechnologies/OneSystemsTechnologies"));
// const EngageRocket = React.lazy(() => import("./pages/CaseStudies/EngageRocket/EngageRocket"));
const Disclaimers = React.lazy(() => import("./pages/Disclaimer/Disclaimer"));
const PrivacyPolicy = React.lazy(() =>
  import("./pages/PrivacyPolicy/PrivacyPolicy")
);
const ThankYou = React.lazy(() => import("./pages/ThankYou/ThankYou"));

const loadPage = Page => (
  <Suspense
    fallback={
      <div className="h-screen grid place-items-center text-gray-600">
        <RippleAnim />
      </div>
    }
  >
    <Page />
  </Suspense>
);

export default function App() {
  let location = useLocation();
  const reCaptchaRef = useRef();

  useEffect(() => {
    trackNavigation(location.pathname);
  }, [location]);

  return (
    <ReCaptchaContext.Provider value={reCaptchaRef}>
      <NavBar />
      <Routes>
        <Route index element={<Home />} />
        <Route path="how-we-work" element={loadPage(HowWeWork)} />
        <Route path="company" element={loadPage(Company)} />
        <Route path="contact-us" element={loadPage(Contact)} />
        <Route path="disclaimer" element={loadPage(Disclaimers)} />
        <Route path="privacy" element={loadPage(PrivacyPolicy)} />
        <Route path="thank-you" element={loadPage(ThankYou)} />
        <Route path="services">
          <Route index element={loadPage(Services)} />
          <Route
            path="web-application-development"
            element={loadPage(WebApplication)}
          />
          <Route path="dev-ops" element={loadPage(DevOps)} />
          <Route path="blockchain-development" element={loadPage(Blockchain)} />
          <Route path="product-design" element={loadPage(ProductDesign)} />
          <Route
            path="team-augmentation"
            element={loadPage(TeamAugmentation)}
          />
          <Route path="project-rescue" element={loadPage(ProjectRescue)} />
          <Route
            path="mobile-application-development"
            element={loadPage(MobileApplication)}
          />
        </Route>
        <Route path="enterprises">
          <Route index element={loadPage(Enterprises)} />
          <Route
            path="application-development"
            element={loadPage(ApplicationDevelopment)}
          />
          <Route
            path="legacy-application"
            element={loadPage(LegacyApplication)}
          />
          <Route
            path="software-integration"
            element={loadPage(SoftwareIntegration)}
          />
        </Route>
        <Route path="startups">
          <Route index element={loadPage(StartUps)} />
          <Route path="mvp" element={loadPage(MVP)} />
        </Route>
        <Route path="case-studies">
          <Route index element={loadPage(CaseStudies)} />
          <Route path="eu-yan-sang" element={loadPage(EuYanSang)} />
          <Route
            path="popular-bookstores"
            element={loadPage(PopularBookStores)}
          />
          <Route path="ntuc-income" element={loadPage(NtucIncome)} />
          <Route path="charles-monat" element={loadPage(CharlesMonat)} />
          <Route path="golden-village" element={loadPage(GoldenVillage)} />
          <Route path="tzu-chi" element={loadPage(TzuChi)} />
          <Route
            path="chiropractic-singapore"
            element={loadPage(ChiropracticSingapore)}
          />
          {/* <Route path="engage-rocket" element={loadPage(EngageRocket)} /> */}
          <Route path="dbs" element={loadPage(DBS)} />
          <Route path="exceltec" element={loadPage(Exceltec)} />
          <Route path="onesystems-technologies" element={loadPage(OneSystemsTechnologies)} />
        </Route>

        {/* 301 for old url */}
        <Route
          path="mobile_application"
          element={<Navigate to="/services/mobile-application-development" />}
        />
        <Route
          path="web_application"
          element={<Navigate to="/services/web-application-development" />}
        />
        <Route path="our-approach" element={<Navigate to="/how-we-work" />} />
        <Route
          path="our-approach?p=ui-ux"
          element={<Navigate to="/services/product-design" />}
        />
        <Route path="api-developement" element={<Navigate to="/services" />} />
        <Route
          path="blockchain-development"
          element={<Navigate to="/services/blockchain-development" />}
        />
        <Route
          path="payment-integration"
          element={<Navigate to="/case-studies" />}
        />
        <Route path="our-works/*" element={<Navigate to="/case-studies" />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      <ReCAPTCHA
        ref={reCaptchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        size="invisible"
      />
      <Footer />
    </ReCaptchaContext.Provider>
  );
}
