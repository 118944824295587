import dayjs from "./dayjs";
const trackKey = "history";

export const trackNavigation = (pathname) => {
  const now = dayjs();
  console.log(now.tz("Asia/Singapore"));
  const history = JSON.parse(localStorage.getItem(trackKey) || "{}");
  const item = `${now
    .tz("Asia/Singapore")
    .format(
      "ddd MMM DD YYYY hh:mm:ss [GMT+0800]"
    )} (Singapore Standard Time) | ${window.origin}${pathname}`;
  history[now.unix()] = item;
  localStorage.setItem(trackKey, JSON.stringify(history));
};

export const getNavigationHistory = () => {
  const history = JSON.parse(localStorage.getItem(trackKey) || "{}");
  return Object.keys(history).map((key) => history[key]);
};

export const clearNavigationHistory = () => {
  localStorage.removeItem(trackKey);
};
