import CaseStudiesSection from "./CaseStudiesSection";
import ContactSection from "./ContactSection";
import CovidSection from "./CovidSection";
import IntroductionSection from "./IntroductionSection";
import ServicesSection from "./ServicesSection";

export default function Home() {
  return (
    <>
      <IntroductionSection />
      <CaseStudiesSection />
      <ServicesSection />
      <CovidSection />
      <ContactSection />
    </>
  );
}
