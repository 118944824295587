import React from "react";

function RippleAnim({
  fill = "red",
  secondaryfill = "red",
  width = 50,
  height = 50,
}) {
  const css = `.nc-loop-ripple-64-icon-f{--animation-duration:1.2s}.nc-loop-ripple-64-icon-f *{transform-origin:50% 50%;animation:nc-loop-ripple-anim var(--animation-duration) infinite cubic-bezier(.215,.61,.355,1)}.nc-loop-ripple-64-icon-f :nth-child(2){animation-delay:calc(var(--animation-duration)/-2)}@keyframes nc-loop-ripple-anim{0%{opacity:1;transform:scale(.2)}100%{opacity:0;transform:scale(1)}}`;

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={secondaryfill}>
        <g className="nc-loop-ripple-64-icon-f">
          <circle cx="32" cy="32" fill={fill} r="32" />
          <circle cx="32" cy="32" r="32" />
        </g>
        <style>{css}</style>
      </g>
    </svg>
  );
}

export default RippleAnim;
