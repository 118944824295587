import { useRef } from "react";
import Text from "../../Components/Text/Text";
import servicesImage from "../../assets/images/services.png";
import Button from "../../Components/Button/Button";
import { ReactComponent as RightTriangleSvg } from "../../assets/svgs/right-triangle.svg";
import { Link } from "react-router-dom";
import { useWindowScroll } from "../../hooks/useWindowScroll";

const SERVICES = [
  {
    label: "MOBILE APP DEVELOPMENT",
    path: "services/mobile-application-development",
  },
  {
    label: "WEB DEVELOPMENT",
    path: "services/web-application-development",
  },
  {
    label: "PRODUCT DESIGN",
    path: "services/product-design",
  },
  {
    label: "TEAM AUGMENTATION",
    path: "services/team-augmentation",
  },
  {
    label: "SOFTWARE PROJECT RESCUE",
    path: "services/project-rescue",
  },
];

export default function ServicesSection() {
  const ref = useRef();
  const { y, height } = useWindowScroll();

  const { offsetTop: elOffsetTop } = ref?.current || {};
  const boundary = elOffsetTop - height / 1.5;
  const show = y > boundary;

  return (
    <section ref={ref}>
      <RightTriangleSvg className="w-full h-40 fill-red-900" />
      <div className="bg-red-900 py-12 px-4">
        <div className="mx-auto container grid md:grid-cols-2 gap-y-4">
          <div>
            <Text
              type="h2"
              size="lg"
              className={`text-white font-semibold transition-all duration-700 ${
                show ? "" : "opacity-0 translate-x-96"
              }`}
            >
              Building customized <br />
              software and solutions
            </Text>

            <div
              className={`transition-all duration-700 ${
                show ? "" : "opacity-0 translate-y-96"
              }`}
            >
              <Text type="p" className="text-white my-8">
                to solve your problems & position you as a leader in your
                industry. Agile Lab's commitment is to make sure your project
                delivers successfully on time, on budget and meets your business
                objectives.
              </Text>

              <ul className="mb-8">
                {SERVICES.map(({ label, path }) => (
                  <li className="my-4" key={label}>
                    <Link to={path}>
                      <Text className="text-white hover:font-bold hover:text-yellow-500">
                        {label}
                      </Text>
                    </Link>
                  </li>
                ))}
              </ul>

              <Button
                path="services"
                variant="outline"
                className="text-white border-yellow-500"
              >
                VIEW ALL SERVICES
              </Button>
            </div>
          </div>
          <div className="grid place-items-center">
            <img
              src={servicesImage}
              className="w-3/4 pointer-events-none"
              alt="Agile Lab's services"
            />
          </div>
        </div>
      </div>
      <RightTriangleSvg className="w-full h-40 fill-red-900 rotate-180" />
    </section>
  );
}
