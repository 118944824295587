import './MenuIcon.css'; // Import the CSS for styling

export const ArrowDownIcon = ({ color, width, height }) => {
  return (
    <svg
      fill={color || "#000000"}
      width={width || "12px"}
      height={height || "12px"}
      viewBox="-0.25 0 4.75 4.75"
      xmlns="http://www.w3.org/2000/svg"
      className="cf-icon-svg"
    >
      <path d="M2.125 3.828a0.258 0.258 0 0 1 -0.182 -0.075l-1.7 -1.7a0.258 0.258 0 0 1 0.364 -0.364L2.125 3.207l1.518 -1.518a0.258 0.258 0 0 1 0.364 0.364l-1.7 1.7a0.258 0.258 0 0 1 -0.182 0.075" />
    </svg>
  );
}

export const MenuIcon = ({ onClick, isOpen }) => {
  const toggleMenu = () => {
    onClick();
  };

  return (
    <div className={`hamburglar ${isOpen ? 'is-open' : 'is-closed'}`} onClick={toggleMenu}>
      <div className="burger-icon">
        <div className="burger-container">
          <span className="burger-bun-top"></span>
          <span className="burger-filling"></span>
          <span className="burger-bun-bot"></span>
        </div>
      </div>
    </div>
  );
}