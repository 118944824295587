import Button from "../../Components/Button/Button";
import Text from "../../Components/Text/Text";
import HeroImage from "../../assets/images/home-hero-bg.png";
import { ReactComponent as RightTriangleSvg } from "../../assets/svgs/right-triangle.svg";

export default function IntroductionSection() {
  return (
    <section className="relative">
      <img
        className="w-full min-h-[800px] h-screen object-cover pointer-events-none"
        src={HeroImage}
        alt="AgileLab"
      />

      <div className="container absolute top-24 sm:top-1/4 lg:top-1/2 left-1/2 -translate-x-1/2 lg:-translate-y-1/2 px-4">
        <Text type="h1" size="xl" className="font-bold animate-fade-in-left">
          Accelerating Your
          <br />
          <Text size="xl" className="text-red-600">
            Digital Revolution
          </Text>
          <br />
          <Text size="xl">
            we.build &#123;for
            <Text size="xl" className="text-yellow-500">
              : :
            </Text>
            people&#125;
          </Text>
        </Text>

        <Text
          type="p"
          className="my-10 max-w-lg xl:max-w-3xl animate-fade-in-left"
        >
          Agile Lab specialises in custom software, mobile, and web application
          development for startups and enterprises. Untangle complex problems
          with our painless development approach. Accelerate your digital ideas
          with real product-market fit.
        </Text>

        <Button path="contact-us" className="animate-fade-in-left">
          WHAT HELP DO YOU NEED?
        </Button>
      </div>

      <RightTriangleSvg className="transition-all absolute bottom-0 fill-white animate-raise-up w-full" />
    </section>
  );
}
