import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import Divider from "../Divider/Divider";
import Text from "../Text/Text";
import ISMSPdf from "../../assets/pdfs/AGILELAB-ISMS.pdf";
import SGISO from "../../assets/pdfs/AGILELAB-ISO.pdf";
import BSDPdf from "../../assets/pdfs/AGILELAB-BSD.pdf";
import ISMSCert from "../../assets/images/cert-isms.jpg";
import BSDCert from "../../assets/images/cert-bsd.png";
import ISOCert from "../../assets/images/cert-iso.png";
import ISOSGCert from "../../assets/images/cert-cyber-trust.png";
import ISOCertDetails from "../../assets/pdfs/AGILELAB-ISOCert.pdf";
import CertSMELogo from "../../assets/images/cert-sme-logo.png";
import CertSME from "../../assets/images/cert-sme.jpg";
import CertCSEA from "../../assets/images/csea_2023_awardee_trademark 2.png";
import CSEAPdf from "../../assets/pdfs/AGILELAB-CSEA.pdf";

const PAGES = [
  {
    title: ["How we work", "/how-we-work"],
    items: [
      ["Our Quality", "/case-studies"],
      ["Design sprints", "/services/product-design"],
    ],
  },
  {
    title: ["Services", "/services"],
    items: [
      ["Mobile App", "/services/mobile-application-development"],
      ["Web App", "/services/web-application-development"],
      ["Team Augmentation", "/team-augmentation"],
      ["Dev Ops", "/services/dev-ops"],
      ["Blockchain", "/services/blockchain-development"],
    ],
  },
  {
    title: ["Enterprises", "/enterprises"],
    items: [
      [
        "Enterprise application development",
        "/enterprises/application-development",
      ],
      ["Legacy application modernization", "/enterprises/legacy-application"],
      ["Software integration", "/enterprises/software-integration"],
    ],
  },
  {
    title: ["Startups", "startups"],
    items: [
      ["Design sprints", "/services/product-design"],
      ["MVP", "/startups/mvp"],
      ["Startup rescue mission", "/services/project-rescue"],
    ],
  },
  {
    title: ["Case Studies", "/case-studies"],
    items: [],
  },
  {
    title: ["Insights", "https://blog.agilelab.sg/"],
    items: [],
  },
  {
    title: ["Company", "/company"],
    items: [],
  },
];

const CERTS = [
  {
    icon: ISOCert,
    alt: "ISO Certification",
    width: 200,
    link: ISOCertDetails,
  },
  {
    icon: ISMSCert,
    alt: "ISMS Certification",
    width: 200,
    link: ISOCertDetails,
  },
  {
    icon: BSDCert,
    alt: "BSD Certification",
    width: 130,
    link: BSDPdf,
  },
  {
    icon: ISOSGCert,
    alt: "SG ISO Certification",
    width: 430,
    link: SGISO,
  },
  {
    icon: CertCSEA,
    alt: "CSE Certification",
    width: 200,
    link: CSEAPdf,
  },
  {
    icon: CertSMELogo,
    alt: "SME500 Certification",
    width: 200,
    link: CertSME,
  },
];

export default function Footer() {
  return (
    <footer>
      <section className="container mx-auto flex flex-col md:flex-row px-4 gap-12">
        <div className="flex flex-col gap-y-1">
          <img
            className="mb-4 w-3/4 max-w-[200px]"
            src={logo}
            alt="Agile Lab"
          />
          <Text size="xs">12 Woodlands Square</Text>
          <Text size="xs">Woods Square Tower 1</Text>
          <Text size="xs">#07-84</Text>
          <Text size="xs">Singapore 737715</Text>
          <a href="mailto:hello@agilelab.sg">
            <Text size="xs">hello@agilelab.sg</Text>
          </a>
        </div>
        <div className="flex-1 flex gap-4 flex-wrap">
          {PAGES.map((page) => (
            <div
              className="flex flex-col flex-1 gap-2 min-w-[100px]"
              key={page.title[0]}
            >
              {page.title[0] === "Insights" ? (
                <a href={page.title[1]} target="_blank" rel="noreferrer">
                  <Text className="font-semibold" size="sm">
                    {page.title[0]}
                  </Text>
                </a>
              ) : (
                <Link to={page.title[1]}>
                  <Text className="font-semibold" size="sm">
                    {page.title[0]}
                  </Text>
                </Link>
              )}

              {page.items.map((item) => (
                <Link key={item[0]} to={item[1]}>
                  <Text size="xs">{item[0]}</Text>
                </Link>
              ))}
            </div>
          ))}
        </div>
      </section>
      <section className="mx-auto container flex items-center justify-start my-12 p-4">
        <div className="flex gap-12">
          {CERTS.map(({ icon, alt, link, width }) => (
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className="grid place-items-center"
            >
              <img
                style={{ width }}
                loading="lazy"
                className="object-contain"
                src={icon}
                alt={alt}
              />
            </a>
          ))}
        </div>
      </section>
      <section className="w-full bg-gray-100">
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-between p-4">
          <Text size="xs">
            © {new Date().getFullYear()} Agile Lab Pte Ltd (UEN 201611401H). All
            rights reserved.
          </Text>
          <div className="flex">
            <Link to="/disclaimer">
              <Text size="xs">Disclaimer</Text>
            </Link>
            <Divider />
            <Link to="/privacy">
              <Text size="xs">Privacy Policy</Text>
            </Link>
            <Divider />
            <a href={ISMSPdf} target="_blank" rel="noreferrer">
              <Text size="xs">Information Security Policy</Text>
            </a>
          </div>
        </div>
      </section>
    </footer>
  );
}
