import NotFoundImage from "../../assets/images/404.png";
import Button from "../../Components/Button/Button";
import Text from "../../Components/Text/Text";

export default function NotFound() {
  return (
    <section className="h-screen">
      <div className="container p-4 flex flex-col m-auto items-center justify-center h-full animate-fade-in-left">
        <img
          className="w-1/3 object-cover pointer-events-none"
          src={NotFoundImage}
          alt="AgileLab"
        />
        <Text type="h1" size="xl" className="font-bold">
          Page Not Found
        </Text>

        <Text
          type="p"
          className="my-10 max-w-lg xl:max-w-3xl"
        >
          Sorry, but the page you are looking for has not been found. Try
          checking the URL for errors, then hit the refresh button on your
          browser.
        </Text>

        <Button path="/">
          HOME PAGE
        </Button>
      </div>
    </section>
  );
}
