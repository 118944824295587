import { useRef } from "react";
import Text from "../../Components/Text/Text";
import rocketImage from "../../assets/images/rocket.png";
import Button from "../../Components/Button/Button";
import { useWindowScroll } from "../../hooks/useWindowScroll";
import { ReactComponent as RightTriangleSvg } from "../../assets/svgs/right-triangle.svg";

export default function ContactSection() {
  const ref = useRef();
  const { y, height } = useWindowScroll();

  const { offsetTop: elOffsetTop } = ref?.current || {};
  const boundary = elOffsetTop - height / 2;
  const show = y > boundary;

  return (
    <section ref={ref} className="mb-32 relative overflow-hidden">
      <RightTriangleSvg className="w-full h-[400px] lg:h-[400px] fill-gray-100" />
      <div className="h-[400px] md:h-[200px] lg:h-[300px] bg-gray-100 relative">
        <div className="container absolute md:bottom-0 left-1/2 -translate-x-1/2 grid md:grid-cols-5 place-items-center px-4">
          <div className="order-2 col-span-2 md:order-1 hidden md:block">
            <img
              src={rocketImage}
              className={`pointer-events-none transition-all duration-700 ${
                show ? "" : "translate-y-96"
              }`}
              alt="Agile Lab's services"
            />
          </div>

          <div
            className={`transition-all duration-700 order-1 col-span-3 md:order-2 mt-8 md:mt-0 xl:mt-24 ${
              show ? "" : "opacity-0 translate-x-96"
            }`}
          >
            <Text type="h2" size="lg" className="font-semibold">
              Get in touch!
            </Text>
            <Text type="p" className="my-8 max-w-[700px]">
              We'd love to work with you! We've worked on a variety of projects
              with a wide range of clients, each with a unique problem and goal.
              We are continually improving our processes to enable our clients
              to reach their objectives through a long term partnership.
            </Text>

            <Button path="contact-us" className="my-4">LET'S START A PROJECT</Button>
          </div>
        </div>
      </div>
      <RightTriangleSvg className="w-full h-20 lg:h-40 hidden md:block fill-white bottom-0 absolute" />
    </section>
  );
}
