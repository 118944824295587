import { useState, useEffect } from "react";

export function useWindowScroll() {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const controller = new AbortController();

    window.addEventListener(
      "scroll",
      () => {
        setPosition({
          x: window.pageXOffset,
          y: window.pageYOffset,
        });
      },
      { signal: controller.signal }
    );

    return () => controller.abort();
  }, [setPosition]);

  return { ...position, height: window.innerHeight };
}
