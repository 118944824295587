import { useEffect, useRef, useState } from "react";
import Text from "../../Components/Text/Text";
import PBImage from "../../assets/images/case-studies/thumb/PB.png";
import EYSImage from "../../assets/images/case-studies/thumb/EYS.png";
import NTUCImage from "../../assets/images/case-studies/thumb/NTUC.png";
import GVImage from "../../assets/images/case-studies/thumb/GV.png";
import Button from "../../Components/Button/Button";
import { useWindowScroll } from "../../hooks/useWindowScroll";

const CASE_STUDIES = [
  {
    title: "Popular Bookstore",
    description:
      "For many of us in Singapore and Malaysia, POPULAR Bookstore has been a household name for decades. From our school days to now as new parents buying stationery and books from the same store, we were excited to get involved and help modernise the POPULAR brand.",
    hint: "E-commerce, Mobile Application",
    path: "case-studies/popular-bookstores",
    img: PBImage,
  },
  {
    title: "Eu Yan Sang",
    description:
      "Turning The Digitalisation Of A 140 Year Old Business Into An Opportunity For Innovation.",
    hint: "E-commerce, Mobile Application",
    path: "case-studies/eu-yan-sang",
    img: EYSImage,
  },
  {
    title: "NTUC Income",
    description:
      "Accelerating Adoption Of Electric Vehicles For A Cleaner, Greener Singapore.",
    hint: "Landing Page, Web Application",
    path: "case-studies/ntuc-income",
    img: NTUCImage,
  },
  {
    title: "Golden Village",
    description:
      "We worked with a leading movie theatre chain to extend their real-time timetable schedules and booking functionality to third-party websites and applications.",
    hint: "Web Application",
    path: "case-studies/golden-village",
    img: GVImage,
  },
];

export default function CaseStudiesSection() {
  const ref = useRef();
  const { y, height } = useWindowScroll();

  const { offsetTop: elOffsetTop } = ref?.current || {};
  const boundary = elOffsetTop - height / 1.5;
  const show = y > boundary;

  return (
    <section ref={ref} className="py-12 px-4">
      <div className="mx-auto container">
        <Text
          type="h2"
          size="lg"
          className={`font-semibold transition-all duration-700 ${
            show ? "" : "opacity-0 translate-x-96"
          }`}
        >
          Case Studies
        </Text>
        <ul className="grid grid-cols-1 xl:grid-cols-2 gap-12 my-12">
          {CASE_STUDIES.map((item, index) => (
            <Item key={item.title} index={index} show={show} {...item} />
          ))}
        </ul>
      </div>
    </section>
  );
}

function Item({ title, description, hint, path, img, index, show }) {
  const [active, setActive] = useState(false);

  useEffect(() => {
    const time = show ? index * 200 : 0;
    const timeId = setTimeout(() => setActive(show), time);
    return () => clearTimeout(timeId);
  }, [show, index]);

  return (
    <li
      className={`transition-all duration-700 ${
        active ? "" : "opacity-0 translate-y-96"
      } flex flex-col md:flex-row rounded-2xl overflow-hidden shadow-lg`}
    >
      <img
        className="object-cover max-w-full md:max-w-[300px] aspect-[300/359]"
        src={img}
        alt={title}
      />
      <div className="p-4 flex flex-col">
        <div className="flex-1">
          <Text className="font-semibold" type="p" size="md">
            {title}
          </Text>
          <Text className="my-4" type="p">
            {description}
          </Text>
          <Text className="text-gray-400" type="p" size="sm">
            {hint}
          </Text>
        </div>
        <Button path={path} variant="outline" size="sm" className="w-fit">
          LEARN MORE
        </Button>
      </div>
    </li>
  );
}
