import { Link } from "react-router-dom";

const BUTTON_VARIANTS = {
  outline: {
    sm: "border-red-600 border-b-2 pb-1 hover:border-b-4 hover:pb-0.5 group-hover:border-b-4 group-hover:pb-0 text-lg rounded-none",
    normal:
      "border-red-600 border-b-4 pb-2 hover:border-b-8 hover:pb-1 group-hover:border-b-8 group-hover:pb-0 text-2xl rounded-none",
  },
  fill: {
    sm: "bg-red-600 shadow-xl text-white text-xs py-3 px-4 rounded-lg",
    normal: "bg-red-600 shadow-xl text-white text-lg py-4 px-6 rounded-xl",
  },
};

export default function Button({
  children,
  disabled = false,
  className,
  size = "normal",
  path,
  variant = "fill",
  onClick,
}) {
  const Component = path ? Link : "button";
  return (
    <Component
      disabled={disabled}
      to={path}
      className={`transition-all ${BUTTON_VARIANTS[variant][size]} font-semibold ${className} disabled:bg-gray-200`}
      onClick={onClick}
    >
      <span size={size}>{children}</span>
    </Component>
  );
}
